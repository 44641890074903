import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import BedOutlinedIcon from '@mui/icons-material/BedOutlined'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HomeIcon from '@mui/icons-material/Home'
import ShowerIcon from '@mui/icons-material/Shower'
import { Dialog, DialogActions, DialogContent, Divider, Link } from '@mui/material'
import Image from 'next/image'
import React, { FC, useState } from 'react'
import { Button } from '@elements/button'
import LoadingSpinner from '@elements/loading-spinner'
import { extractDomainHeroImg } from '@utils/helpers/domain-api.helpers'
import { AddPropertyDialogContentProps } from './add-property-dialog'
import { AddPropertyDialogForm } from './add-property-dialog-form'

export const AddPropertyMobileContent: FC<AddPropertyDialogContentProps> = ({
  open,
  handleClose,
  domainPropertySuggestion,
  domainPropertyData,
  propertyDataIsLoading,
  manual_address,
  domainListingData,
  methods,
}) => {
  const domainHeroImg = extractDomainHeroImg(domainPropertyData)
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false)
  const [disableAddPropertyButton, setDisableAddPropertyButton] = useState<boolean>(false)

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      aria-labelledby="add_property_dialog_mobile"
      data-testid="add_property_dialog_mobile"
    >
      <DialogContent sx={{ marginTop: 0, paddingTop: 0, paddingX: 0, position: 'relative' }}>
        <div className="relative h-[30vh] w-full overflow-hidden">
          <Image
            src={domainHeroImg || '/icons/no_preview.svg'}
            layout="fill"
            objectFit="cover"
            alt="Property image"
            className="absolute inset-0"
            unoptimized
          />
        </div>

        <DialogActions
          sx={{ position: 'absolute', top: 0, justifyContent: 'flex-start', paddingLeft: 2, paddingTop: 3 }}
        >
          <Link
            onClick={handleClose}
            underline="none"
            sx={{
              color: 'grey',
              backgroundColor: 'white',
              borderRadius: '50%',
              padding: '8px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '35px',
              height: '35px',
              cursor: 'pointer',
            }}
          >
            <ArrowBackRoundedIcon />{' '}
          </Link>
        </DialogActions>

        {propertyDataIsLoading && <LoadingSpinner />}
        {(domainPropertyData || manual_address) && !propertyDataIsLoading && (
          <>
            <div className="px-5 pb-3 pt-4 text-xl font-light text-black">
              {domainPropertyData?.address || manual_address?.full_address}
            </div>

            <div className="grid grid-cols-4 gap-4 text-center text-sm">
              <div className="flex flex-col items-center text-background-dark-300">
                <HomeIcon />
                <span>{domainPropertyData?.areaSize ? `${domainPropertyData?.areaSize}m²` : '--'}</span>
              </div>
              <div className="flex flex-col items-center text-background-dark-300">
                <DirectionsCarIcon />
                <span>
                  {domainPropertyData?.carSpaces
                    ? `${domainPropertyData.carSpaces} ${domainPropertyData.carSpaces === 1 ? 'garage' : 'garages'}`
                    : '--'}
                </span>
              </div>
              <div className="flex flex-col items-center text-background-dark-300">
                <BedOutlinedIcon />
                <span>
                  {domainPropertyData?.bedrooms
                    ? `${domainPropertyData.bedrooms} ${domainPropertyData.bedrooms === 1 ? 'room' : 'rooms'}`
                    : '--'}
                </span>
              </div>
              <div className="flex flex-col items-center text-background-dark-300">
                <ShowerIcon />
                <span>
                  {domainPropertyData?.bathrooms
                    ? `${domainPropertyData.bathrooms} ${domainPropertyData.bathrooms === 1 ? 'bath' : 'baths'}`
                    : '--'}
                </span>
              </div>
            </div>
          </>
        )}
        <div className="pt-4">
          <Divider />
        </div>
        <AddPropertyDialogForm
          domainPropertySuggestion={domainPropertySuggestion}
          domainProperty={domainPropertyData}
          domainListing={domainListingData}
          manualAddress={manual_address}
          handleClose={handleClose}
          setIsFormSubmitting={setIsFormSubmitting}
          setDisableAddPropertyButton={setDisableAddPropertyButton}
          methods={methods}
        />
        <div className="fixed bottom-0 left-0 mt-auto w-full bg-white">
          <Divider />
          <div className="px-5 sm:px-6"></div>
          <div className={'flex items-center justify-between p-5 sm:p-6'}>
            <div className="shrink-0">
              <Button
                type="submit"
                muiSize={'small'}
                isLoading={isFormSubmitting}
                disabled={disableAddPropertyButton}
                form="add-property-form"
              >
                Get started
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { TransactionType, typeOfSale } from '@home-in/models'
import { Card } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { Icon, Icons } from '@elements/icons/icon'
import mock_property from '@public/mock/checklist-property.png'
import { useAppDispatch } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { disableModal, enableModal, setModalContent } from '@redux/reducers/modal'
import { fireToastNotifications } from '@redux/reducers/notifications'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { getPropertySubtitle } from '../property-card.helpers'
import { RemovePropertyModal } from '../remove-property-modal'
import { styles } from './property-card.styles'

interface Props {
  imageUrl?: string
  auctionDate?: string
  saleType?: typeOfSale
  settlementDate?: string
  isRemovable?: boolean
  address: string
  removeProperty: Function
  tags?: string[]
  link: string
  transactionType: TransactionType
  propertyId?: string
}

export const PropertyCardV2: FC<Props> = (props) => {
  const dispatch = useAppDispatch()
  const imgSource = props.imageUrl ?? mock_property

  const hideModal = () => {
    dispatch(setModalContent(null))
    dispatch(disableModal())
  }

  const removePropertyHandler = async () => {
    hideModal()
    const result = props.removeProperty()
    dispatch(fireToastNotifications({ result, optimisticUIUpdate: true, successMessage: 'Removed property' }))
  }

  const showRemovePropertyModal = () => {
    dispatch(
      trackButtonClick({
        action: ButtonClickEventNames.RemovePropertyIcon,
        category: AnalyticsCategories.Property,
      })
    )

    dispatch(
      setModalContent(
        <RemovePropertyModal
          removable={props?.isRemovable || false}
          removeProperty={removePropertyHandler}
          cancel={hideModal}
          transactionType={props.transactionType}
        />
      )
    )
    dispatch(enableModal())
  }

  const getFloatingButton = () => {
    const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      showRemovePropertyModal()
    }

    return (
      <button onClick={clickHandler} className={styles.floatingButton}>
        <Icon asset={Icons.trash} size={24} />
      </button>
    )
  }

  const getTags = () => {
    const tagStyle = 'pr-4 text-sm text-muted-dark-600'

    const saleStatus = getPropertySubtitle({
      auctionDate: props.auctionDate,
      saleType: props.saleType,
      settlementDate: props.settlementDate,
    })

    const tags = [saleStatus]

    if (props.tags) {
      tags.push(...props.tags)
    }

    return tags.map((tag, index) => (
      <span key={index} className={tagStyle}>
        {tag}
      </span>
    ))
  }

  return (
    <Card data-testid={`property-card-${props.propertyId}`} className={styles.card}>
      <Link href={props.link}>
        <div className={styles.cardBody}>
          <div className={styles.imageWrapper}>
            <Image src={imgSource} alt={props.address} layout="fill" unoptimized className={styles.image} />

            {getFloatingButton()}
          </div>

          <div className={styles.contentWrapper}>
            <div className={styles.address}>{props.address}</div>

            {getTags()}
          </div>
        </div>
      </Link>
    </Card>
  )
}

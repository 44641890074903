import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import React from 'react'
import {
  AUTOCOMPLETE_MANUAL_ADDRESS_LINK_STYLE,
  AUTOCOMPLETE_PAPER_COMPONENT_STYLE,
} from './autocomplete-style-overrides'

export default function CustomAutocompletePaperComponent({
  props,
  handleOpenManualAddressDialog,
}: {
  props: React.HTMLAttributes<HTMLElement>
  handleOpenManualAddressDialog: () => void
}) {
  return (
    <Box sx={AUTOCOMPLETE_PAPER_COMPONENT_STYLE} onMouseDown={(event) => event.preventDefault()}>
      {props.children}
      {/* Reason to disable onMouseDown above - https://stackoverflow.com/a/73266338 */}
      <Box>
        <Link
          sx={AUTOCOMPLETE_MANUAL_ADDRESS_LINK_STYLE}
          onClick={() => {
            handleOpenManualAddressDialog()
          }}
        >
          Add address manually
        </Link>
      </Box>
    </Box>
  )
}

// TODO
// ONLY move these style overrides to ThemeProvider when rolled out
// since that would be a global style override
// it would affect the previous search bar because it also uses Autocomplete

export const AUTOCOMPLETE_TEXTFIELD_STYLE_OVERRIDE = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    backgroundColor: 'white',
    legend: {
      marginLeft: '30px',
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingLeft: '20px !important',
    borderRadius: '48px',
    backgroundColor: 'white',
  },
  '& .MuiInputLabel-outlined': {
    paddingLeft: '20px',
  },
  '& .MuiInputLabel-shrink': {
    marginLeft: '20px',
    paddingLeft: '10px',
    paddingRight: 0,
    background: 'white',
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    boxShadow: '0px 1px 14px 0px #00000040',
  },
  '& .MuiOutlinedInput-input::placeholder': {
    color: 'rgba(0, 0, 0, 0.54)',
    opacity: 1,
  },
}

export const AUTOCOMPLETE_ROOT_STYLE_OVERRIDE = {
  // Removes the green border when focused
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
    boxShadow: '0px 1px 14px 0px #00000040',
    border: 'none',
  },
}

export const AUTOCOMPLETE_POPPER_COMPONENT_STYLE_OVERRIDE = {
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
  borderRadius: '14px',
  overflow: 'hidden',
  padding: '10px',
  backgroundColor: '#FFFFFF',
  marginTop: '8px!important',
}

export const AUTOCOMPLETE_MANUAL_ADDRESS_LINK_STYLE = {
  paddingLeft: '16px',
  paddingRight: '16px',
  color: '#0611FF',
  textDecoration: 'underline',
  cursor: 'pointer',
}

export const AUTOCOMPLETE_PAPER_COMPONENT_STYLE = {
  marginBottom: '12px',
}

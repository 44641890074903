import { Address } from '@home-in/models'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Button } from '@elements/button'
import { ManualAddressProps } from '@elements/forms/groups/manual-address'
import { ManualAddressV2 } from '@elements/forms/groups/manual-address-v2'
import { useAppDispatch } from '@redux/hooks'
import { setManualAddress } from '@redux/reducers/add-property'
import { mergeAddress } from '@redux/utils/utils'

interface ManualAddPropertyDialogProps extends Partial<ManualAddressProps> {
  open: boolean
  handleClose: () => void
  handleOpenAddPropertyDialog: () => void
  formMethods: UseFormReturn<Address>
}

export const ManualAddPropertyDialog: FC<ManualAddPropertyDialogProps> = ({
  open,
  handleClose,
  handleOpenAddPropertyDialog,
  formMethods,
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { control, handleSubmit } = formMethods

  const dispatch = useAppDispatch()
  const onSubmit = (form: any) => {
    // Save the property details in Redux store for the add-property-dialog form to use
    const address = mergeAddress({
      isManual: true,
      manualAddress: {
        ...form['manual_add_property_dialog_from_property_search'],
        manual_entry: true,
      },
      domainAddress: undefined,
    })
    if (address) {
      dispatch(setManualAddress(address))
    }
    handleClose()
    handleOpenAddPropertyDialog()
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-testid="manual_address_dialog"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="m-0 p-0">
          <DialogActions sx={{ justifyContent: 'flex-start', paddingLeft: 2, paddingTop: 3 }}>
            <Link onClick={handleClose} underline="none" sx={{ color: 'grey' }}>
              {' '}
              <ArrowBackRoundedIcon />{' '}
            </Link>
          </DialogActions>
          <DialogTitle id="responsive-dialog-title">{'Tell us about your property'}</DialogTitle>
          <DialogContent sx={{ paddingTop: '8px!important' }}>
            <ManualAddressV2 name="manual_add_property_dialog_from_property_search" control={control} />
            <button type="button" className="mt-4 text-secondary underline" onClick={handleClose}>
              Add address using search
            </button>
          </DialogContent>
          <Divider />
          <div className="px-5 sm:px-6"></div>
          <div className={'flex items-center justify-between p-5 sm:p-6'}>
            <div className="shrink-0">
              <Button type="submit" muiSize="large" isLoading={false} disabled={false}>
                Next
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  )
}

import { Address, PropertyType, SupportedPropertyType, TransactionType } from '@home-in/models'
import { useEffect, useState } from 'react'
import { fireMixpanelErrorForAddProperty } from '@elements/property-search/form-helpers'
import { useAppDispatch } from '@redux/hooks'

export const useAddBuyPropertyErrorUnsupportedPropertyType = ({
  transactionType,
  propertyType,
  address,
}: {
  transactionType: TransactionType
  propertyType: PropertyType | undefined
  address: Address | undefined
}) => {
  const [unsupportedPropertyTypeError, setUnsupportedPropertyTypeError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const isUnsupportedPropertyType =
      transactionType === TransactionType.Buying && !!propertyType && !SupportedPropertyType.includes(propertyType)

    if (isUnsupportedPropertyType) {
      fireMixpanelErrorForAddProperty({
        address,
        dispatch,
        addPropertyError: 'buy-property-type-not-supported',
        propertyType,
      })
    }
    setUnsupportedPropertyTypeError(isUnsupportedPropertyType)
  }, [transactionType, propertyType])

  return unsupportedPropertyTypeError
}

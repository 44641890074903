import { CampaignOffer } from '@home-in/models'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useAddCampaignIdMutation } from '@redux/apis/profile'
import { useAppSelector } from '@redux/hooks'

// This hook is used to activate the Yello offer for new and existing customers
export const useActivateOffers = () => {
  const data = useAppSelector((state) => state.profile)
  const user = useAppSelector((state) => state.auth.user)
  const utmId = user?.utm_id

  const [addCampaignId] = useAddCampaignIdMutation()

  useEffect(() => {
    const accountExistsInPega = !!data?.created_at
    const activatedCampaigns = data?.campaigns?.map((campaign) => campaign.campaign_id)

    if (accountExistsInPega) {
      const yelloOffer001Activated = activatedCampaigns?.includes(CampaignOffer.YelloOffer001)
      const cbaFreeContractReviewActivated = activatedCampaigns?.includes(CampaignOffer.CBAFreeContractReview)
      const cbaWorkplaceBanking001Activated = activatedCampaigns?.includes(CampaignOffer.CBAWorkplaceBanking001)

      // https://home-in.atlassian.net/browse/BEL-2784
      // Restrict existing customers to only have one offer at a time (Yello / Workplace Banking offers)
      if (utmId !== CampaignOffer.CBAFreeContractReview && (yelloOffer001Activated || cbaWorkplaceBanking001Activated))
        return

      // FIXME: `CampaignOffer` is used for the utm, offer and campaign IDs, these are three different things
      if (!cbaWorkplaceBanking001Activated && utmId === CampaignOffer.CBAWorkplaceBanking001) {
        addCampaignId({ campaignId: CampaignOffer.CBAWorkplaceBanking001 })
      } else if (!yelloOffer001Activated) {
        // FIXME: to be checked if we should use utmId here too
        const isUtmIdYello = data?.utm_id === CampaignOffer.YelloOffer001

        if (isUtmIdYello) {
          // New customer
          addCampaignId({ campaignId: CampaignOffer.YelloOffer001 })
        } else {
          // Note: we can use cookies since existing customers don't have the "activate your email" step.
          // The login step is one click, so we can use a simple cookie to activate the offer since it's impossible
          // for a user to swap devices in the middle of the login process.
          const cookieCampaignId = Cookies.get('campaign_id')
          const yelloCampaignCookieExists = cookieCampaignId === CampaignOffer.YelloOffer001

          if (yelloCampaignCookieExists) {
            // Existing customer
            addCampaignId({ campaignId: CampaignOffer.YelloOffer001 })
          }
        }
      }

      if (!cbaFreeContractReviewActivated) {
        if (utmId === CampaignOffer.CBAFreeContractReview) {
          addCampaignId({ campaignId: CampaignOffer.CBAFreeContractReview })
        }
      }
    }
  }, [data, utmId])
}

import { Address, State, TransactionType } from '@home-in/models'
import { useEffect, useState } from 'react'
import { fireMixpanelErrorForAddProperty } from '@elements/property-search/form-helpers'
import { useAppDispatch } from '@redux/hooks'

export const useAddSellPropertyErrorOutsideOfNSW = ({
  transactionType,
  address,
}: {
  transactionType: TransactionType
  address: Address | undefined
}) => {
  const [unsupportedStateError, setUnsupportedStateError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const unsupportedState = transactionType === TransactionType.Selling && address?.state !== State.NSW

    if (unsupportedState) {
      fireMixpanelErrorForAddProperty({
        address,
        dispatch,
        addPropertyError: 'sell-property-outside-of-nsw',
      })
    }
    setUnsupportedStateError(unsupportedState)
  }, [transactionType])

  return unsupportedStateError
}

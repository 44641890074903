import { CampaignOffer } from '@home-in/models'
import React from 'react'
import { CbaFreeContractReviewOfferTile } from '@elements/banners/cba-free-contract-review-offer'
import { CBAWorkPlaceBankingOfferTile } from '@elements/banners/cba-workplace-banking-offer'
import { CBAYelloOfferTile } from '@elements/banners/cba-yello-offer'
import { useGetOffers } from '@hooks/useGetOffers'
import { EnhancedHomeInnerSection, EnhancedHomeSubtitle } from '../parts/activity'

interface OfferTileComponentMap {
  [key: string]: React.FC
}

const offerTilesComponentMap: OfferTileComponentMap = {
  [CampaignOffer.YelloOffer001]: CBAYelloOfferTile,
  [CampaignOffer.CBAFreeContractReview]: CbaFreeContractReviewOfferTile,
  [CampaignOffer.CBAWorkplaceBanking001]: CBAWorkPlaceBankingOfferTile,
}

export const YourOffers = () => {
  const campaignOffers = useGetOffers()
  const offerTiles = campaignOffers.map((campaignOffer) => offerTilesComponentMap[campaignOffer])

  if (!offerTiles.length) {
    return <></>
  }

  return (
    <div className="mt-8">
      <EnhancedHomeInnerSection>
        <EnhancedHomeSubtitle>Your offers</EnhancedHomeSubtitle>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          {offerTiles.map((OfferTile, index) => (
            <OfferTile key={index} />
          ))}
        </div>
      </EnhancedHomeInnerSection>
    </div>
  )
}

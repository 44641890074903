import { TransactionType } from '@home-in/models'
import React from 'react'
import { Button } from '@elements/button'
import { InfoIcon } from '@elements/info-icon'

interface PropertyModalContentProps {
  removable: boolean
  removeProperty: () => void
  cancel: () => void
  transactionType: TransactionType
}

const ContactSupportContent = ({ transactionType }: { transactionType: TransactionType }) =>
  transactionType === TransactionType.Selling ? (
    <>
      <h6>Remove property</h6>
      <p className="mt-8 text-muted">You have already requested a contract preparation.</p>
      <p className="mb-8 text-muted">
        If you no longer want to proceed contact your conveyancer who can advise of any associated costs.
      </p>
    </>
  ) : (
    <>
      <h6>Are you sure you want to remove this property?</h6>
      <p className="mt-8 text-muted">
        You have already signed the contract. If you want to rescind this contract, you’ll need to ensure you have made
        adequate legal enquiries as you could incur major financial penalties.
      </p>
      <p className="mb-8 text-muted">
        If you still want to proceed and permanently remove this property from your dashboard, Get in Touch with your
        Buying Assistant.
      </p>
    </>
  )
const RemovePropertyContent = () => (
  <>
    <h6>Are you sure you want to remove this property?</h6>
    <p className="mb-6 mt-4 text-muted">
      The property will be permanently removed from your dashboard. Any associated documents will remain available under
      the Documents tab.
    </p>
    <div className="mb-8 flex text-left">
      <InfoIcon customColour="muted" size={18} />
      <span className="ml-3 text-sm text-muted">
        <i>
          <strong>Please note:</strong>
        </i>{' '}
        If any conveyancing services relating to this property are in progress at the time of removing, you will incur
        any associated conveyancing fees as per the Cost Agreement.
      </span>
    </div>
  </>
)

export const RemovePropertyModal = ({
  removeProperty,
  cancel,
  removable,
  transactionType,
}: PropertyModalContentProps) => {
  return (
    <div className="max-w-sm text-center">
      {removable ? <RemovePropertyContent /> : <ContactSupportContent transactionType={transactionType} />}
      {removable && (
        <Button overrideFullWidth={true} className="mb-2 w-full" callback={removeProperty}>
          Remove
        </Button>
      )}
      <div>
        <Button overrideFullWidth={true} className="mb-2" variant={'outlined'} callback={cancel} category="secondary">
          Cancel
        </Button>
      </div>
    </div>
  )
}

import { useFlags } from 'launchdarkly-react-client-sdk'
import React from 'react'
import {
  CombinedProperty,
  isBuyProperty,
  isSellProperty,
  SellProperty,
  BuyProperty,
} from '../../utils/get-properties.helper'
import { SellPropertyCard, PropertyCard } from './property-card'
import { BuyPropertyCardV2 } from './v2/property-card.buy'
import { SellPropertyCardV2 } from './v2/property-card.sell'

const SellPropertyCardComponent = ({ property }: { property: SellProperty }) => {
  return (
    <div key={property.propertyId}>
      <SellPropertyCard
        removable={property.removable}
        propertyId={property.propertyId}
        property={{
          address: property.address,
          state: property.state,
          addressKey: property.address,
          imageUrl: property.imageUrl,
        }}
      />
    </div>
  )
}

const BuyPropertyCardComponent = ({ property }: { property: BuyProperty }) => {
  return (
    <div key={property.propertyId}>
      <PropertyCard
        hbjId={property.hbjId}
        propertyId={property.propertyId}
        property={{
          address: property.address,
          auctionDate: property.auctionDate,
          contractExchanged: property.contractExchanged,
          imageUrl: property.imageUrl,
          saleType: property.saleType,
          settlementDate: property.settlementDate,
          state: property.state,
          removable: property.removable,
          addressKey: property.address,
        }}
      />
    </div>
  )
}

const SellPropertyCardComponentV2 = ({ property }: { property: SellProperty }) => {
  return (
    <div key={property.propertyId}>
      <SellPropertyCardV2
        removable={property.removable}
        propertyId={property.propertyId}
        property={{
          address: property.address,
          state: property.state,
          addressKey: property.address,
          imageUrl: property.imageUrl,
        }}
      />
    </div>
  )
}

const BuyPropertyCardComponentV2 = ({ property }: { property: BuyProperty }) => {
  return (
    <div key={property.propertyId}>
      <BuyPropertyCardV2
        hbjId={property.hbjId}
        propertyId={property.propertyId}
        property={{
          address: property.address,
          auctionDate: property.auctionDate,
          contractExchanged: property.contractExchanged,
          imageUrl: property.imageUrl,
          saleType: property.saleType,
          settlementDate: property.settlementDate,
          state: property.state,
          removable: property.removable,
          addressKey: property.address,
        }}
      />
    </div>
  )
}

export const PropertyCards = ({ properties }: { properties: CombinedProperty[] }) => {
  const { propertyCardV2 } = useFlags()

  if (propertyCardV2) {
    return (
      <>
        {properties.map((data, idx) => {
          if (isSellProperty(data)) {
            return <SellPropertyCardComponentV2 key={idx} property={data} />
          }

          if (isBuyProperty(data)) {
            return <BuyPropertyCardComponentV2 key={idx} property={data} />
          }

          return null
        })}
      </>
    )
  }

  return (
    <>
      {properties.map((data, idx) => {
        if (isSellProperty(data)) {
          return <SellPropertyCardComponent key={idx} property={data} />
        }

        if (isBuyProperty(data)) {
          return <BuyPropertyCardComponent key={idx} property={data} />
        }

        return null
      })}
    </>
  )
}

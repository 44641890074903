import { StateType, TransactionType } from '@home-in/models'
import React, { FC } from 'react'
import { useArchiveSellPropertyMutation } from '@redux/apis/sell-property'
import { PropertyCardV2 } from './property-card'

interface Property {
  addressKey: string
  address: string
  state: StateType
  imageUrl?: string | undefined
}

interface Props {
  propertyId: string
  property: Property
  removable?: boolean
}

export const SellPropertyCardV2: FC<Props> = ({ property, propertyId, removable }) => {
  const [archiveSellProperty] = useArchiveSellPropertyMutation()

  const removePropertyHandler = () => {
    archiveSellProperty({ propertyId })
  }

  const imageUrl = property.imageUrl ? property.imageUrl : undefined

  return (
    <PropertyCardV2
      transactionType={TransactionType.Selling}
      imageUrl={imageUrl}
      isRemovable={removable}
      address={property.address}
      removeProperty={removePropertyHandler}
      tags={['Sell Property']}
      link={`sell/checklist/${propertyId}`}
      propertyId={propertyId}
    />
  )
}

import { MuscovyPropertySchemaType } from '@home-in/models'
import Image, { StaticImageData } from 'next/image'
import Link from 'next/link'
import React, { FC } from 'react'
import { Card } from '@elements/card'
import { Icon, Icons } from '@elements/icons/icon'
import { generateTrashIconId } from '@features/home/utils/generate-trash-icon'

const TrashIcon = ({ id, handleRemoveClick }: { id: string; handleRemoveClick: () => void }) => (
  <div
    onClick={(e) => {
      e.stopPropagation() // Prevents the click event from bubbling up to the parent Link component
      handleRemoveClick()
    }}
    className="cursor-pointer rounded-full text-black transition-all duration-300 hover:text-error"
    id={id}
  >
    <Icon asset={Icons.trash} size={24} />
  </div>
)

export enum PropertyCardType {
  buy = 'buy',
  sell = 'sell',
}

interface PropertyCardUI {
  link: string
  handleRemoveClick: () => void
  propertyAddress: string
  cardSubtitle?: string
  imgSource: string | StaticImageData
  sellChecklists: MuscovyPropertySchemaType[]
  propertyType: PropertyCardType
}

export const PropertyCardUIContainer: FC<{}> = ({ children }) => (
  <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3">{children}</div>
)

const PropertyCardTag: FC<{}> = ({ children }) => (
  <div className="rounded-lg bg-light px-2 py-1.5 text-sm text-black">{children}</div>
)

const PropertyCardItem: FC<PropertyCardUI> = ({
  link,
  handleRemoveClick,
  propertyAddress,
  cardSubtitle,
  imgSource,
  propertyType,
}) => {
  const purchaseTypeString = propertyType === PropertyCardType.buy ? 'Buy Property' : 'Sell Property'
  return (
    <Card className="cursor-pointer text-primary-600 hover:text-primary sm:text-black">
      <Link
        href={{
          pathname: link,
        }}
      >
        {/* Role is used by the smoke test add property selector */}
        <div data-testid="addPropertyCard">
          <div className="relative mb-3 h-56 min-h-56 overflow-hidden rounded">
            <div className="absolute right-3 top-3 z-10 block rounded-full bg-white p-1">
              <TrashIcon id={generateTrashIconId(true, propertyAddress)} handleRemoveClick={handleRemoveClick} />
            </div>
            <Image src={imgSource} alt={propertyAddress} layout="fill" unoptimized className="object-cover" />
          </div>

          <div className="mb-1 flex">
            <div className="text-lg font-bold text-black">{propertyAddress}</div>
          </div>
          <div className="mt-3 flex items-center gap-4 text-sm text-black">
            <PropertyCardTag>{purchaseTypeString}</PropertyCardTag>
            {cardSubtitle && <PropertyCardTag>{cardSubtitle}</PropertyCardTag>}
          </div>
          <a className="block w-full rounded py-2 text-sm font-semibold  transition-all active:bg-light">
            View full checklist
          </a>
        </div>
      </Link>
    </Card>
  )
}

export const PropertyCardUIWrapper: FC<PropertyCardUI> = ({
  link,
  cardSubtitle,
  handleRemoveClick,
  imgSource,
  propertyAddress,
  sellChecklists,
  propertyType,
}) => {
  return (
    <PropertyCardItem
      link={link}
      cardSubtitle={cardSubtitle}
      handleRemoveClick={handleRemoveClick}
      imgSource={imgSource}
      propertyAddress={propertyAddress}
      sellChecklists={sellChecklists}
      propertyType={propertyType}
    />
  )
}

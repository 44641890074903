import { Address, MuscovyPropertySchemaType, TransactionType } from '@home-in/models'
import { useEffect, useState } from 'react'
import { fireMixpanelErrorForAddProperty, isSellAddressAlreadyAdded } from '@elements/property-search/form-helpers'
import { useAppDispatch } from '@redux/hooks'

export const useAddSellPropertyErrorAlreadyAdded = ({
  transactionType,
  address,
  sellProperties,
}: {
  transactionType: TransactionType
  address: Address | undefined
  sellProperties: MuscovyPropertySchemaType[] | undefined
}) => {
  const [alreadyAddedError, setAlreadyAddedError] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const alreadyAdded =
      transactionType === TransactionType.Selling && isSellAddressAlreadyAdded(address, sellProperties)

    if (alreadyAdded) {
      fireMixpanelErrorForAddProperty({
        address,
        dispatch,
        addPropertyError: 'sell-property-already-added',
      })
    }
    setAlreadyAddedError(alreadyAdded)
  }, [transactionType])

  return alreadyAddedError
}

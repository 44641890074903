import { GetMetadataResponse, USER_METADATA_ENUM } from '@home-in/models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRouter } from 'next/router'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { EmojiFeedbackBanner } from '@elements/banners/emoji-feedback-banner'
import { SellSideNewFeatureBanner } from '@elements/banners/sell-side-new-feature'
import PropertySearchBar from '@elements/property-search/property-search-bar-container'
import { LoadingWrapper } from '@elements/status-handlers/loading-wrapper'
import { NotificationGroup, NotificationItem } from '@features/home/components/notification-group'
import { PreApprovalCard } from '@features/home/components/preapproval-card'
import { useAcknowledgeSellSideGetStartedCard } from '@hooks/useAcknowledgeSellSideLookingToSellCard'
import useCBAPreApprovalHBJ from '@hooks/useCBAPreApprovalHBJ'
import { useProperties } from '@hooks/useProperties'
import { useGetUserMetadataQuery } from '@redux/apis/profile'
import { useAppSelector } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'
import { getActiveTransactions } from '@utils/helpers/buy-properties.helpers'
import { AddPropertyCard } from '../components/add-property-card'
import { PropertyCardUIContainer } from '../components/property-card/property-card-ui'
import { PropertyCards } from '../components/property-card/property-cards'
import { YourOffers } from '../components/your-offers'
import { getLenderTileDetails } from '../utils/get-lender-tile-details'
import { getCombinedPropertiesSortedByPropertyAddedDateTime } from '../utils/get-properties.helper'

export const EnhancedHomeInnerSection = ({ children }: { children: React.ReactNode }) => (
  <div className="space-y-4">{children}</div>
)
export const EnhancedHomeSubtitle = ({ children }: { children: React.ReactNode }) => (
  <div className="text-sm font-medium text-muted-700 sm:text-base">{children}</div>
)

const showSellSideGetStartedCard = ({
  userMetadata,
  userMetadataIsLoading,
  userMetadataIsError,
  sellProperties,
}: {
  userMetadata: GetMetadataResponse | undefined
  userMetadataIsLoading: boolean
  userMetadataIsError: boolean
  sellProperties: Record<string, any>[]
}) => {
  if (!sellProperties || userMetadataIsLoading || userMetadataIsError || !userMetadata) {
    return false // don't show card when data still loading
  }
  if (sellProperties.length > 0) {
    return false // don't show if property added already
  }
  return (
    userMetadata?.find((metadata) => metadata.metadata_type === USER_METADATA_ENUM.acknowledgeSellSideGetStartedCard)
      ?.value !== true
  )
}

export const Activity = () => {
  const cbaPreApprovalHBJ = useCBAPreApprovalHBJ()
  const { enhanceAddProperty } = useFlags()
  const router = useRouter()

  const {
    dataWithoutArchivedProperties: properties,
    unarchivedBuyProperties,
    unarchivedSellProperties,
    loading: isCombinedPropertiesLoading,
    isError: isCombinedPropertiesError,
  } = useProperties()
  const isAnyProperties = !!properties?.length && properties?.length > 0
  const isAnyBuySideProperties = !!unarchivedBuyProperties?.length && unarchivedBuyProperties?.length > 0

  // Workaround for multi-transaction issue. Only display lender tile for the first HBJ.
  // https://home-in.atlassian.net/browse/TREE-1151?focusedCommentId=59198
  const home = useAppSelector((state) => state.home)
  const firstActiveTransaction = getActiveTransactions(home)?.[0]
  const lenderTile = getLenderTileDetails(firstActiveTransaction!)

  const items: NotificationItem[] = [
    {
      component: <PreApprovalCard {...cbaPreApprovalHBJ?.loan!} />,
      active: !!cbaPreApprovalHBJ?.loan?.loanAmount,
    },
    {
      illustration: lenderTile.illustration,
      title: lenderTile.title,
      message: lenderTile.message,
      callback: lenderTile.callback,
      active: lenderTile.active && isAnyBuySideProperties,
    },
  ]

  // BEL-2151 dismissable card
  const {
    data: userMetadata,
    isLoading: userMetadataIsLoading,
    isError: userMetadataIsError,
  } = useGetUserMetadataQuery()
  const { acknowledgeSellSideGetStartedCard } = useAcknowledgeSellSideGetStartedCard()
  const sellSideNewFeatureBannerDismissed = useRef(false) // workaround saving metadata latency
  const isSellSideGetStartedCardVisible =
    !sellSideNewFeatureBannerDismissed.current &&
    showSellSideGetStartedCard({
      userMetadata,
      userMetadataIsLoading,
      userMetadataIsError,
      sellProperties: unarchivedSellProperties,
    })
  const dispatch = useDispatch()

  const LegacyHomePageLayout = () => {
    return (
      <div>
        {isSellSideGetStartedCardVisible && (
          <SellSideNewFeatureBanner
            callbackDismiss={() => {
              acknowledgeSellSideGetStartedCard()
              sellSideNewFeatureBannerDismissed.current = true
              dispatch(
                trackButtonClick({
                  action: ButtonClickEventNames.SellSideAddPropertyCardDismissed,
                  category: AnalyticsCategories.HomeScreen,
                })
              )
            }}
            callbackAction={() => {
              router.push('/property/add')
              dispatch(
                trackButtonClick({
                  action: ButtonClickEventNames.SellSideAddPropertyCardActioned,
                  category: AnalyticsCategories.HomeScreen,
                })
              )
            }}
          />
        )}

        <EmojiFeedbackBanner />

        <NotificationGroup items={items.filter((item) => item.active)} />

        <LoadingWrapper className="mb-4" isLoading={isCombinedPropertiesLoading} isError={isCombinedPropertiesError}>
          <PropertyCardUIContainer>
            <PropertyCards properties={getCombinedPropertiesSortedByPropertyAddedDateTime(properties)} />
          </PropertyCardUIContainer>
        </LoadingWrapper>

        {!isAnyProperties && !isCombinedPropertiesLoading && <AddPropertyCard />}
        <YourOffers />
      </div>
    )
  }

  const EnhancedHomePageLayout = () => {
    return (
      <LoadingWrapper className="mb-4" isLoading={isCombinedPropertiesLoading} isError={isCombinedPropertiesError}>
        <div className="space-y-8">
          <EnhancedHomeInnerSection>
            <PropertySearchBar />
            {/* Add lender and CBA pre-approval tile */}
            <NotificationGroup items={items.filter((item) => item.active)} />

            <PropertyCardUIContainer>
              <PropertyCards properties={getCombinedPropertiesSortedByPropertyAddedDateTime(properties)} />
            </PropertyCardUIContainer>
            {!isAnyProperties && !isCombinedPropertiesLoading && <AddPropertyCard />}
          </EnhancedHomeInnerSection>

          <YourOffers />
          {isSellSideGetStartedCardVisible && (
            <EnhancedHomeInnerSection>
              <EnhancedHomeSubtitle>Your notifications</EnhancedHomeSubtitle>
              <SellSideNewFeatureBanner
                callbackDismiss={() => {
                  acknowledgeSellSideGetStartedCard()
                  sellSideNewFeatureBannerDismissed.current = true
                  dispatch(
                    trackButtonClick({
                      action: ButtonClickEventNames.SellSideAddPropertyCardDismissed,
                      category: AnalyticsCategories.HomeScreen,
                    })
                  )
                }}
                callbackAction={() => {
                  router.push('/property/add')
                  dispatch(
                    trackButtonClick({
                      action: ButtonClickEventNames.SellSideAddPropertyCardActioned,
                      category: AnalyticsCategories.HomeScreen,
                    })
                  )
                }}
              />
            </EnhancedHomeInnerSection>
          )}

          <EnhancedHomeInnerSection>
            <EnhancedHomeSubtitle>Tell us about your experience</EnhancedHomeSubtitle>
            <EmojiFeedbackBanner />
          </EnhancedHomeInnerSection>
        </div>
      </LoadingWrapper>
    )
  }

  return enhanceAddProperty ? <EnhancedHomePageLayout /> : <LegacyHomePageLayout />
}

import SearchIcon from '@mui/icons-material/Search'
import { AutocompleteRenderInputParams } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import React from 'react'
import { AUTOCOMPLETE_TEXTFIELD_STYLE_OVERRIDE } from './autocomplete-style-overrides'

export default function SearchInput({
  params,
  setSearchText,
  searchText,
}: {
  params: AutocompleteRenderInputParams
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  searchText: string
}) {
  return (
    <TextField
      {...params}
      onChange={(event) => setSearchText(event.target.value)}
      sx={AUTOCOMPLETE_TEXTFIELD_STYLE_OVERRIDE}
      placeholder={'Search to add a property'}
      InputProps={{
        ...params.InputProps,
        // Adds the search icon to the input field
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: params.InputProps.endAdornment,
      }}
      inputProps={{ ...params.inputProps, value: params.inputProps.value ? params.inputProps.value : searchText }}
    />
  )
}

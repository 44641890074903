import {
  Address,
  DomainListingResponse,
  DomainProperty,
  DomainPropertyResponse,
  PropertySaleType,
  PropertyType,
  State,
  TransactionType,
  YesNo,
} from '@home-in/models'
import { MonetizationOnRounded, SellRounded } from '@mui/icons-material'
import { DialogContent } from '@mui/material'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import { SubmitHandler, UseFormReturn } from 'react-hook-form'
import { useIntercom } from 'react-use-intercom'
import { ErrorAlert } from '@elements/error-alert'
import { DateTimeField, Listbox, RadioCards } from '@elements/forms'
import { LabelRequired } from '@elements/forms/label-required'
import { useAddBuyPropertyErrorUnsupportedPropertyType } from '@hooks/useAddBuyPropertyErrorUnsupportedPropertyType'
import { useAddSellPropertyErrorAlreadyAdded } from '@hooks/useAddSellPropertyErrorAlreadyAdded'
import { useAddSellPropertyErrorOutsideOfNSW } from '@hooks/useAddSellPropertyErrorOutsideOfNSW'
import { useFireMixpanelErrorForNoHbjId, useGetHbjIdToAddProperty } from '@hooks/useGetHbjIdToAddProperty'
import { useSellProperties } from '@hooks/useSellProperties'
import { useAddPropertyMutation } from '@redux/apis/checklist'
import { useAddSellChecklistMutation } from '@redux/apis/sell-checklist'
import { useAddSellPropertyMutation } from '@redux/apis/sell-property'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { mergeAddress } from '@redux/utils/utils'
import { sevenDaysAgo } from '@utils/helpers/date.helpers'
import { enumToSelectOptions, filterEnumToRadioCardInputs } from '@utils/helpers/enum.helpers'
import {
  usePrefillFormWithDomainListingData,
  usePrefillPropertyTypeWithDomainPropertyData,
  useResetAuctionDate,
} from './form-helpers'
import { submitAddPropertyForm } from './submit-add-property-form'

interface AddPropertyDialogFormProps {
  domainPropertySuggestion: DomainProperty | undefined
  domainProperty: DomainPropertyResponse | undefined
  domainListing: DomainListingResponse | undefined
  manualAddress?: Address
  handleClose: () => void
  setIsFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setDisableAddPropertyButton: React.Dispatch<React.SetStateAction<boolean>>
  methods: UseFormReturn<AddPropertyDialogFormFields, any>
}

export interface AddPropertyDialogFormFields {
  transactionType: TransactionType
  auctionDate?: Date | string
  contractOfSale?: string
  saleType?: PropertySaleType
  propertyType?: PropertyType
  hasCosReady?: YesNo
}

export const AddPropertyDialogForm: FC<AddPropertyDialogFormProps> = ({
  domainPropertySuggestion,
  domainProperty,
  manualAddress,
  domainListing,
  handleClose,
  setIsFormSubmitting,
  setDisableAddPropertyButton,
  methods,
}) => {
  const { control, handleSubmit, watch } = methods
  const saleType = watch('saleType')
  const transactionType = watch('transactionType')
  const propertyType = watch('propertyType')
  useResetAuctionDate(methods, saleType)
  usePrefillFormWithDomainListingData(methods, domainListing)
  usePrefillPropertyTypeWithDomainPropertyData(methods, domainProperty)

  const dispatch = useAppDispatch()
  const router = useRouter()
  const { show: showIntercom } = useIntercom()

  // For adding sell property
  const sellProperties = useSellProperties()
  const [addSellProperty] = useAddSellPropertyMutation()
  const [addSellChecklist] = useAddSellChecklistMutation()
  const intercom = useIntercom()

  // For adding buy property
  const hbj = useAppSelector((state) => state.home.hbj)
  const propertyData = useAppSelector((state) => state.checklist.checklists) || []
  const [addBuyProperty] = useAddPropertyMutation()
  const { hbjIdToAddProperty, validToAddProperty, errorSlugToAddProperty, errorMessageToAddProperty } =
    useGetHbjIdToAddProperty({
      address: domainPropertySuggestion,
      hbj,
      manual_address: manualAddress,
      propertyData,
    })
  useFireMixpanelErrorForNoHbjId({
    address: domainPropertySuggestion,
    dispatch,
    errorSlugToAddProperty,
  })

  const address = mergeAddress({
    isManual: !!manualAddress,
    manualAddress,
    domainAddress: {
      id: domainPropertySuggestion?.id,
      address: domainPropertySuggestion?.address,
      addressComponents: domainPropertySuggestion?.addressComponents,
    },
  })
  const alreadyAddedError = useAddSellPropertyErrorAlreadyAdded({
    transactionType,
    address,
    sellProperties,
  })
  const outsideOfNSWError = useAddSellPropertyErrorOutsideOfNSW({
    transactionType,
    address,
  })
  const unsupportedPropertyTypeError = useAddBuyPropertyErrorUnsupportedPropertyType({
    transactionType,
    propertyType,
    address,
  })

  useEffect(() => {
    setDisableAddPropertyButton(
      alreadyAddedError ||
        outsideOfNSWError ||
        unsupportedPropertyTypeError ||
        (transactionType === TransactionType.Buying && !validToAddProperty)
    )
  }, [alreadyAddedError, outsideOfNSWError, unsupportedPropertyTypeError, validToAddProperty, transactionType])

  const onSubmit: SubmitHandler<AddPropertyDialogFormFields> = async (form) => {
    // We don't need to setIsFormSubmitting(false) since router takes customer to
    // checklists once a property is added successfully and this state is no longer relevant
    // (Parent dialog would be dismissed during redirect)
    setIsFormSubmitting(true)
    await submitAddPropertyForm({
      form,
      domainPropertySuggestion,
      domainProperty,
      manualAddress,
      dispatch,
      hbjIdToAddProperty,
      addBuyProperty,
      addSellProperty,
      addSellChecklist,
      router,
      intercom,
    })
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="m-0 p-0" id="add-property-form">
        <DialogContent className="mb-16 mt-4 space-y-9 md:space-y-11 lg:mb-2">
          <RadioCards
            control={control}
            label="Are you buying or selling this property?"
            labelClassName={'text-base lg:text-lg'}
            name="transactionType"
            inputs={[
              { input: TransactionType.Buying, icon: MonetizationOnRounded },
              { input: TransactionType.Selling, icon: SellRounded },
            ]}
            disabled={false}
            className="my-0 py-0"
          />
          {transactionType === TransactionType.Selling && (
            <>
              {outsideOfNSWError ? (
                <div>
                  <ErrorAlert heading="Sorry you can only sell NSW properties through Home-in right now" />
                </div>
              ) : (
                alreadyAddedError && (
                  <div>
                    <ErrorAlert
                      heading="Oops! It looks like you've already added this property"
                      message="If we've got this wrong and you need assistance, please contact our support team. Otherwise, feel
                      free to go back and view your existing property."
                      buttonCallback={handleClose}
                      buttonText="Go Back"
                    />
                  </div>
                )
              )}
            </>
          )}
          {transactionType === TransactionType.Buying && (
            <>
              {validToAddProperty ? (
                <>
                  <div>
                    <p className="mb-1 text-base lg:text-lg">
                      <LabelRequired>What is the property type?</LabelRequired>
                    </p>
                    <Listbox
                      label="Property type"
                      control={control}
                      values={enumToSelectOptions(PropertyType)}
                      defaultEmpty
                      name="propertyType"
                      className="my-0 py-0"
                      floatingLabel={true}
                      disabled={false}
                    />
                    {unsupportedPropertyTypeError && (
                      <div className="mt-4">
                        <ErrorAlert
                          heading={`Home-in doesn't offer conveyancing services for ${propertyType} properties`}
                          message={`At this time, Home-in does not cover conveyancing services for ${propertyType} properties. If you require additional assistance, please reach out to the Home-in support team.`}
                        />
                      </div>
                    )}
                  </div>

                  <RadioCards
                    control={control}
                    label="What is the sale type?"
                    labelClassName={'text-base lg:text-lg'}
                    name="saleType"
                    inputs={filterEnumToRadioCardInputs(PropertySaleType)}
                    disabled={false}
                    className="my-0 py-0"
                  />

                  {saleType === PropertySaleType.Auction && (
                    <div>
                      <p className="mb-1 text-base lg:text-lg">When is the auction?</p>
                      <div>
                        <DateTimeField
                          label="Auction date"
                          min={sevenDaysAgo()}
                          labelClassName={'text-sm lg:text-lg'}
                          name="auctionDate"
                          shrinkIfNoError
                          floatingLabel={false}
                          control={control}
                          className="mb-0 py-0"
                        />
                      </div>
                    </div>
                  )}
                  <RadioCards
                    control={control}
                    label="Have you and the Seller signed the contract of sale?"
                    name="contractOfSale"
                    labelClassName={'text-base lg:text-lg'}
                    disabled={false}
                    inputs={filterEnumToRadioCardInputs(YesNo)}
                    description={
                      domainProperty?.state === State.VIC
                        ? 'Choose yes, if you can provide us with a copy of your contract signed and dated by all purchaser(s) and seller(s)'
                        : 'Choose yes, if you can provide us with a copy of your contract signed and dated by the seller(s)'
                    }
                    className="my-0 py-0"
                  />
                </>
              ) : (
                <div className="mt-4">
                  <ErrorAlert
                    heading="Please contact your buying assistant to add this property"
                    message={errorMessageToAddProperty}
                    buttonCallback={showIntercom}
                    buttonText="Get in Touch"
                  />
                </div>
              )}
            </>
          )}
        </DialogContent>
      </form>
    </>
  )
}

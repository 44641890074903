import {
  Address,
  DomainListingResponse,
  DomainProperty,
  DomainPropertyResponse,
  TransactionType,
} from '@home-in/models'
import { useMediaQuery, useTheme } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useGetDomainListingData } from '@hooks/useGetDomainListingData'
import { useGetDomainPropertyData } from '@hooks/useGetDomainPropertyData'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { trackPageView } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { AddPropertyDesktopDialog } from './add-property-dialog-desktop'
import { AddPropertyDialogFormFields } from './add-property-dialog-form'
import { AddPropertyMobileContent } from './add-property-dialog-mobile'

interface AddPropertyDialogProps {
  open: boolean
  handleClose: () => void
}
export interface AddPropertyDialogContentProps {
  open: boolean
  handleClose: () => void
  domainPropertySuggestion: DomainProperty | undefined
  domainPropertyData: DomainPropertyResponse | undefined
  domainListingData: DomainListingResponse | undefined
  propertyDataIsLoading: boolean
  manual_address: Address | undefined
  methods: UseFormReturn<AddPropertyDialogFormFields, any>
}

export const AddPropertyDialog: FC<AddPropertyDialogProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { domain_property, manual_address } = useAppSelector((state) => state.addProperty)
  const { domainPropertyData, isLoading: propertyDataIsLoading } = useGetDomainPropertyData(domain_property?.id)
  const { domainListingData } = useGetDomainListingData(domainPropertyData)
  const methods = useForm<AddPropertyDialogFormFields>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    criteriaMode: 'all',
    defaultValues: {
      transactionType: TransactionType.Buying,
    },
  })

  useEffect(() => {
    dispatch(
      trackPageView({
        action: 'Add property',
        category: AnalyticsCategories.PageView,
      })
    )
  }, [])

  return (
    <>
      {isMobile ? (
        <AddPropertyMobileContent
          open={open}
          handleClose={handleClose}
          domainPropertySuggestion={domain_property}
          domainPropertyData={domainPropertyData}
          domainListingData={domainListingData}
          manual_address={manual_address}
          propertyDataIsLoading={propertyDataIsLoading}
          methods={methods}
        />
      ) : (
        <AddPropertyDesktopDialog
          open={open}
          handleClose={handleClose}
          domainPropertySuggestion={domain_property}
          domainPropertyData={domainPropertyData}
          domainListingData={domainListingData}
          manual_address={manual_address}
          propertyDataIsLoading={propertyDataIsLoading}
          methods={methods}
        />
      )}
    </>
  )
}

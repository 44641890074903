import { DomainProperty, DomainPropertySuggestionResponse } from '@home-in/models'
import { AutocompleteRenderOptionState } from '@mui/material'
import React from 'react'

export default function CustomAutocompleteOption({
  props,
  option,
}: {
  props: React.HTMLAttributes<HTMLLIElement> & {
    key: any
  }
  option: DomainPropertySuggestionResponse[0]
}) {
  // Removes the bottom border for the last option
  const { id, address } = option as DomainProperty

  return (
    <li {...props} key={id || address || ''} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
      {address}
    </li>
  )
}

import React, { useState } from 'react'
import { Card } from '@elements/card'
import { LinkStyled } from '@elements/forms/link'
import { Icon, Icons } from '@elements/icons/icon'
import { Modal } from '@elements/modal'

const LearnMoreModalContent = () => (
  <>
    <div className="mb-2 text-xl font-semibold sm:text-2xl lg:mb-4">Learn More</div>
    <p>
      You’re eligible for the offer if you use the in-app legal services provided through Home-in. To secure the offer
      you will need to engage the services of Home-in’s partner law firm via the Home-in app. Customers will receive one
      Contract Review at no cost. If you proceed to use the Home-in app to settle on your property, full conveyancing
      and disbursements are additional and charged at cost.
    </p>
    <p>
      In conjunction to this offer, you may be eligible for an additional discount if you 1) Settle on a property with a
      CommBank home loan, and 2) Use the in-app legal services provided through Home-in. Eligible customers will receive
      a discount of $500 off the conveyancing fee, and disbursements are additional and charged at cost. Home-in
      reserves the right to terminate the offer and change the pricing at any time. Find out more about Home-in’s
      pricing by visiting{' '}
      <LinkStyled target="_blank" href="https://www.home-in.com.au/pricing">
        home-in.com.au/pricing
      </LinkStyled>
      .
    </p>
  </>
)

export const CbaFreeContractReviewOfferTile = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Card size="small">
        <Icon asset={Icons.cbaLogo} size={40} />
        <div className="mb-1 mt-4 font-semibold">Free contract review offer</div>
        <p className="mb-2">
          Get your first contract of sale reviewed for free. If you proceed to use the Home-in app to settle on your
          property, conveyancing fees and disbursements are additional and charged at cost.
        </p>
        <a
          onClick={() => setShowModal(true)}
          className="cursor-pointer text-sm text-primary-700 hover:text-primary-800 sm:text-base"
        >
          Learn more
        </a>
      </Card>
      <Modal size="medium" active={showModal} variant="normal" toggler={() => setShowModal(false)} quickDismiss={true}>
        <LearnMoreModalContent />
      </Modal>
    </>
  )
}

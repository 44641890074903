import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card } from '@elements/card'
import { LinkStyled } from '@elements/forms/link'
import { Icon, Icons } from '@elements/icons/icon'
import { Modal } from '@elements/modal'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories, ButtonClickEventNames } from '@utils/helpers/analytics.enum'

const LearnMoreCBAModalContents = () => {
  return (
    <>
      <div className="mb-2 text-xl font-semibold sm:text-2xl lg:mb-4">Learn More</div>
      <div className="prose max-w-none">
        <p className="text-lg font-semibold">
          <sup>*</sup>To be eligible to receive the discount you must meet each of the following requirements:
        </p>
        <ul className="list-none">
          <li>
            <span className="font-semibold">a)</span> you must either:
            <ul className="list-none pl-6">
              <li>
                <span className="font-semibold">i)</span> sell your current property using Home-in and then buy your
                next property using Home-in within 6 months after your sell side settlement; or
              </li>
              <li>
                <span className="font-semibold">ii)</span> buy your next property using Home-in and then sell one of
                your current properties using Home-in within 12 months after your buy side settlement; and
              </li>
            </ul>
          </li>
          <li>
            <span className="font-semibold">b)</span> each property (buy and sell) must be associated with a CommBank
            home loan; and
          </li>
          <li>
            <span className="font-semibold">c)</span> you must engage the services of Home-in&apos;s partner law firm
            via the Home-in app on both of your property transactions (buy and sell).
          </li>
        </ul>
        <p>
          This offer is only available through CommBank&apos;s proprietary channels, it is not available to customers
          that originate through Bankwest, Unloan or a broker channel.
        </p>
        <p>
          To secure the offer you will need to engage the services of Home-in&apos;s partner law firm via the Home-in
          app. Fulfilment of the offer will be completed by Home-in.
        </p>
        <p>
          If you sell and then buy, you will receive the $1,000 discount on your final Home-in buy side settlement
          invoice. If you buy and then sell, you will receive a $500 discount on your Home-in buy side settlement
          invoice and a $500 discount on Home-in sell side settlement invoice.
        </p>
        <p>
          Disbursements are additional and charged at cost. Home-in reserves the right to terminate the offer and change
          the pricing at any time. Find out more about Home-in&apos;s pricing by visiting{' '}
          <LinkStyled target="_blank" href="https://www.home-in.com.au/pricing">
            https://www.home-in.com.au/pricing
          </LinkStyled>
          .
        </p>
        <p>
          This offer is only available to eligible CommBank Yello customers and is activated via the Yello Hub within
          the CommBank app. CommBank Yello is a customer recognition program offered by Commonwealth Bank of Australia
          ABN 48 123 123 124 AFSL 234945. You may be eligible to receive this offer if you meet the eligibility criteria
          of any of the benefit sets as part of CommBank Yello. Exclusions apply, see{' '}
          <LinkStyled target="_blank" href="https://www.commbank.com.au/commbank-yello">
            https://www.commbank.com.au/commbank-yello
          </LinkStyled>{' '}
          for full terms and conditions. CommBank Yello Terms and Conditions and eligibility criteria are subject to
          change.
        </p>
      </div>
    </>
  )
}

export const CBAYelloOfferTile = () => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()

  const onClickLearnMore = () => {
    setShowModal(true)
    dispatch(
      trackButtonClick({
        action: ButtonClickEventNames.CBAYelloOffer001TileLearnMore,
        category: AnalyticsCategories.HomeScreen,
      })
    )
  }

  return (
    <>
      <Card size="small">
        <Icon asset={Icons.cbaLogo} size={40} />
        <div className="mb-1 mt-4 font-semibold">Exclusive CommBank Yello offer</div>
        <p className="mb-2">
          Save up to $1000 off your conveyancing fee when you buy and sell with Home-in and settle with a CommBank home
          loan<sup>*</sup>
        </p>
        <a
          onClick={() => onClickLearnMore()}
          className="cursor-pointer text-sm text-primary-700 hover:text-primary-800 sm:text-base"
        >
          Learn more
        </a>
      </Card>
      <Modal size="medium" active={showModal} variant="normal" toggler={() => setShowModal(false)} quickDismiss={true}>
        <LearnMoreCBAModalContents />
      </Modal>
    </>
  )
}

import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { AddPropertyBanner } from '@elements/banners/add-property-banner'

export const AddPropertyCard: FC = () => {
  const router = useRouter()
  return (
    <AddPropertyBanner
      heading="Buying or selling a property?"
      content="Add a property to unlock your personalised checklist, engage your conveyancer and request a contract review or contract preparation. "
      callback={() => router.push('/property/add')}
    />
  )
}

import React from 'react'
import { MUIAlert, MUIAlertProps } from '@elements/alert'
import { Button } from '@elements/button'

export const ErrorAlert = ({
  heading,
  message,
  buttonText,
  buttonCallback,
}: {
  heading: string
  message?: string
  buttonText?: string
  buttonCallback?: () => void
}) => (
  <MUIAlert variant={'standard'} severity="error" size={'md'}>
    <div className="mb-1 font-semibold">{heading}</div>
    {message && <div>{message}</div>}
    {buttonText && buttonCallback && (
      <Button className="mt-3 font-normal" size="sm" callback={buttonCallback}>
        {buttonText}
      </Button>
    )}
  </MUIAlert>
)

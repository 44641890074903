import { SliceProperty, TransactionType } from '@home-in/models'
import React, { FC } from 'react'
import { useRemovePropertyMutation } from '@redux/apis/checklist'
import { PropertyCardV2 } from './property-card'

export const BuyPropertyCardV2: FC<SliceProperty> = ({ hbjId, property, propertyId }) => {
  const [removeProperty] = useRemovePropertyMutation()

  const removePropertyHandler = () => {
    removeProperty({ hbj: hbjId, propertyId: propertyId })
  }

  const imageUrl = property.imageUrl ? `${property.imageUrl}/800x800` : undefined

  return (
    <PropertyCardV2
      transactionType={TransactionType.Buying}
      imageUrl={imageUrl}
      auctionDate={property.auctionDate}
      saleType={property.saleType}
      settlementDate={property.settlementDate}
      isRemovable={property.removable}
      address={property.address}
      removeProperty={removePropertyHandler}
      tags={['Buy Property']}
      link={`/checklist/${hbjId}/${propertyId}`}
      propertyId={propertyId}
    />
  )
}

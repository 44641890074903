const card = 'relative cursor-pointer hover:text-primary h-full'
const cardBody = 'p-4'
const imageWrapper = 'relative h-56 min-h-56 mb-4'
const image = 'object-cover rounded-xl'
const contentWrapper = 'mb-1'
const address = 'text-lg text-muted-dark-800'
const floatingButton =
  'absolute right-3 top-3 block text-black rounded-full bg-white p-1 transition-all duration-300 hover:text-error'

export const styles = {
  card,
  cardBody,
  imageWrapper,
  image,
  contentWrapper,
  address,
  floatingButton,
}

import {
  Address,
  DomainListingResponse,
  DomainPropertyResponse,
  MuscovyPropertySchemaType,
  PropertySaleType,
  PropertyType,
} from '@home-in/models'
import { AnyAction } from '@reduxjs/toolkit'
import { Dispatch, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { trackEvent } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { AddPropertyDialogFormFields } from './add-property-dialog-form'

export const usePrefillPropertyTypeWithDomainPropertyData = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  domainProperty?: DomainPropertyResponse
) => {
  useEffect(() => {
    if (!domainProperty) return
    methods.setValue('propertyType', PropertyType[domainProperty?.propertyType as keyof typeof PropertyType], {
      shouldTouch: true,
      shouldValidate: true,
    })
  }, [domainProperty])
}

export const usePrefillFormWithDomainListingData = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  listing: DomainListingResponse | undefined
) => {
  useEffect(() => {
    if (listing?.saleDetails?.saleMethod === 'auction' && listing?.saleDetails?.auctionDetails?.auctionSchedule) {
      methods.setValue('saleType', PropertySaleType.Auction, { shouldTouch: true })
      const date = new Date(listing?.saleDetails?.auctionDetails?.auctionSchedule?.openingDateTime || 'invalid')
      // Convert the UTC time given to the local time based on the users system clock
      const dateWithLocalTimezoneOffset = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      const isoDateStringWithoutSeconds = (dateWithLocalTimezoneOffset.toISOString() as string).split('.')?.[0]
      if (!isNaN(date.getTime())) {
        // Auction Date input field does not support seconds
        methods.setValue('auctionDate', isoDateStringWithoutSeconds, { shouldTouch: true })
      }
    }
    if (listing?.saleDetails?.saleMethod === 'privateTreaty') {
      methods.setValue('saleType', PropertySaleType.PrivateTreaty, { shouldTouch: true })
    }
  }, [listing])
}

export const useResetAuctionDate = (
  methods: UseFormReturn<AddPropertyDialogFormFields>,
  saleType?: PropertySaleType
) => {
  // Reset auction date if sale type changes
  useEffect(() => {
    if (saleType !== PropertySaleType.Auction) methods.resetField('auctionDate')
  }, [saleType])
}

export const isSellAddressAlreadyAdded = (
  address: Address | undefined,
  sellProperties: MuscovyPropertySchemaType[] | undefined
): boolean => {
  // Check for address already added
  const hasAddedSameProperty = sellProperties?.find((property) => {
    if (address?.full_address && property.address) {
      return address.full_address === property.address
    }
  })

  if (hasAddedSameProperty) {
    return true
  }
  return false
}

interface UseFireMixpanelErrorForAddPropertyProps {
  address: Address | undefined
  dispatch: Dispatch<AnyAction>
  addPropertyError: string
  propertyType?: PropertyType
}

export const fireMixpanelErrorForAddProperty = ({
  address,
  dispatch,
  addPropertyError,
  propertyType,
}: UseFireMixpanelErrorForAddPropertyProps) => {
  dispatch(
    trackEvent({
      action: `Add Property Error: ${addPropertyError}`,
      category: AnalyticsCategories.AddProperty,
      value: {
        address: address?.full_address,
        state: address?.state,
        propertyType,
      },
    })
  )
}

import { DomainPropertySuggestionResponse } from '@home-in/models'
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import { useAppDispatch } from '@redux/hooks'
import { setDomainProperty } from '@redux/reducers/add-property'
import AutocompleteSearchInput from './autocomplete-components/autocomplete-search-input'
import CustomAutocompleteOption from './autocomplete-components/autocomplete-search-option'
import CustomAutocompletePaperComponent from './autocomplete-components/autocomplete-search-paper-component'
import CustomAutocompletePopperComponent from './autocomplete-components/autocomplete-search-popper-component'
import { AUTOCOMPLETE_ROOT_STYLE_OVERRIDE } from './autocomplete-components/autocomplete-style-overrides'

export default function SearchProperty({
  searchText,
  setSearchText,
  addressOptions,
  setSelectedOption,
  selectedOption,
  handleOpenAddPropertyDialog,
  handleOpenManualAddressDialog,
}: {
  searchText: string
  setSearchText: React.Dispatch<React.SetStateAction<string>>
  addressOptions: DomainPropertySuggestionResponse
  setSelectedOption: React.Dispatch<React.SetStateAction<DomainPropertySuggestionResponse[0] | null>>
  selectedOption: DomainPropertySuggestionResponse[0] | null
  handleOpenAddPropertyDialog: () => void
  handleOpenManualAddressDialog: () => void
}) {
  const [optionCount, setOptionCount] = useState(0)
  const [openOptionsList, setOpenOptionsList] = useState(false)
  const dispatch = useAppDispatch()

  // Calling filter options to count number of options in the list
  // which is used to remove the the last option bottom border
  const filterOptions = React.useCallback((options) => {
    if (optionCount !== options.length) {
      setOptionCount(options.length)
    }
    return options
  }, [])

  const handlePropertySelection = (newValue: DomainPropertySuggestionResponse[0] | null) => {
    if (newValue) {
      // Save the property details in Redux store for the add-property-dialog form to use
      dispatch(setDomainProperty(newValue))
      setSelectedOption(newValue)
      handleOpenAddPropertyDialog()
      setSearchText(newValue.address || '')
    }
  }

  const handleClear = () => {
    setSelectedOption(null)
    setSearchText('')
  }

  const handleOnChange = (newValue: DomainPropertySuggestionResponse[0] | null, reason: AutocompleteChangeReason) => {
    if (reason === 'selectOption') {
      handlePropertySelection(newValue)
    } else if (reason === 'clear') {
      handleClear()
    }
  }
  const getNoOptionsText = (searchText: string): string => {
    const alphaChars = searchText.replace(/[^a-zA-Z]/g, '')
    // You need to search atleast 4 characters to get the no matchin address found message
    // This is to avoid showing the message when user is typing the first few characters
    // FYI - When you enter 2 characters, it will show actual search results instead of this get no options text message
    return alphaChars.length < 5
      ? 'Search results will appear here.'
      : 'No matching addresses found. Try refining your search or add manually.'
  }

  // Only show the top 5 search results
  const displayingAddressOptions = addressOptions.slice(0, 5)

  return (
    <Autocomplete
      forcePopupIcon={false}
      open={openOptionsList}
      noOptionsText={getNoOptionsText(searchText)}
      id="search-property"
      options={displayingAddressOptions}
      value={selectedOption}
      onChange={(_event, newValue, reason) => handleOnChange(newValue, reason)}
      sx={AUTOCOMPLETE_ROOT_STYLE_OVERRIDE}
      onFocus={() => setOpenOptionsList(true)}
      onBlur={() => setOpenOptionsList(false)}
      getOptionLabel={(option: any) => option.address}
      PopperComponent={(props) => <CustomAutocompletePopperComponent props={props} />}
      PaperComponent={(props) => (
        <CustomAutocompletePaperComponent props={props} handleOpenManualAddressDialog={handleOpenManualAddressDialog} />
      )}
      renderOption={(props, option) => <CustomAutocompleteOption props={props} option={option} />}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <AutocompleteSearchInput params={params} setSearchText={setSearchText} searchText={searchText} />
      )}
    />
  )
}
